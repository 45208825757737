import styled from '@emotion/styled'

export const Questionnaire = styled.div`
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: calc(5vh);
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: calc(15vh);
  }
`
