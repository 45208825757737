import * as React from 'react'
import styled from '@emotion/styled'

const StyledAnswerGroup = styled.div``

type AnswerGroupProps = {
  children?: any
}

function QuestionHeader(props: AnswerGroupProps) {
  return <StyledAnswerGroup className="columns is-mobile is-multiline is-centered">{props.children}</StyledAnswerGroup>
}

export default QuestionHeader
