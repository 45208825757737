import * as React from 'react'
import styled from '@emotion/styled'

const StyledHeader = styled.h1`
  cursor: default;
  color: white;
`

type QuestionHeaderProps = {
  header: string
}

function QuestionHeader(props: QuestionHeaderProps) {
  return <StyledHeader>{props.header}</StyledHeader>
}

export default QuestionHeader
