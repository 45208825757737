import * as React from 'react'
import { observer } from 'mobx-react-lite'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import AppState from '../appState'
import { Questionnaire } from '../components/Questionnaire'
import { Question } from '../components/Question'
import ProductCard from '../components/ProductCard'
import ProductSection from '../components/ProductSection'
import styled from '@emotion/styled'

export const ResultsKey = 'Results'

if (typeof window !== `undefined` && !(window as any).IntersectionObserver) {
  require('intersection-observer')
}

const ResultsHeader = styled.h2`
  color: white;
`

function Quiz() {
  const store = React.useContext(AppState)
  const ref = React.useRef<HTMLDivElement>(null)

  store.updateRef(ResultsKey, ref)

  return (
    <IndexLayout>
      <Page>
        <Container>
          <Questionnaire>
            {store.visibleQuestions.map(q => (
              <Question key={q.key} question={q} />
            ))}
          </Questionnaire>
        </Container>
        {store.isDone && (
          <React.Fragment>
            <ResultsHeader ref={ref}>{store.translations.results.title}</ResultsHeader>
            <p>{store.translations.results.info}</p>
          </React.Fragment>
        )}
      </Page>
      {store.isDone && (
        <ProductSection>
          {store.products.map((r, i) => (
            <ProductCard key={i} results={r} index={i} />
          ))}
        </ProductSection>
      )}
    </IndexLayout>
  )
}

export default observer(Quiz)
