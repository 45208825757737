import React from 'react'
import AppState, { VisibleResult, ProductDto } from '../appState'
import { useStaticQuery, graphql } from 'gatsby'
import { observer } from 'mobx-react-lite'
import Img, { FluidObject } from 'gatsby-image'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRetweet } from '@fortawesome/free-solid-svg-icons'

type ImageResult = {
  name: string
  fluid: FluidObject
}

function processImages(imageQuery: any): ImageResult[] {
  return imageQuery.images.edges.map((e: any) => {
    return {
      name: e.node.relativePath,
      fluid: e.node.childImageSharp.fluid
    }
  })
}

const PaddedCard = styled.div`
  margin: 6%;
  height: 100%;
`

const ImageContainer = styled.div`
  height: 50vh;
`

const ProductTitle = styled.h1`
  text-transform: uppercase;
  text-align: center;
  font-family: KievitPro-Bold;
  margin-bottom: 0 !important;
`

const Subtitle = styled.p`
  text-align: center;
  font-style: italic;
`

const OtherProduct = styled.a`
  color: black;
  text-decoration: underline;

  &:visited {
    color: black;
  }
`

const ColumnsWithoutMargin = styled.div`
  margin-bottom: 0 !important;
`

const UlColumnsWithoutMargin = styled.ul`
  margin-bottom: 0;
`

const Bullets = styled.ul`
  margin-bottom: 0;
  list-style: inside;
`

const NonStretchedImage = (props: any) => {
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth,
        margin: '0 auto' // Used to center the image
      }
    }
  }

  return <Img {...normalizedProps} imgStyle={{ objectFit: 'contain' }} style={{ position: 'unset', height: '100%' }} />
}

type LinkOrTextProps = {
  product: ProductDto
  focusedProduct: ProductDto
  updater: () => void
}

const LinkOrText = (props: LinkOrTextProps) => {
  if (props.product === props.focusedProduct) {
    return <span>{props.product.name}</span>
  }

  return <OtherProduct onClick={props.updater}>{props.product.name}</OtherProduct>
}

function Product(props: { results: VisibleResult; index: number }) {
  const store = React.useContext(AppState)
  const images = processImages(
    useStaticQuery(
      graphql`
        query {
          images: allFile(filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      `
    )
  )

  const { results } = props
  const updater = (productIndex: number) => store.setSubProductFocus(props.index, productIndex)
  const image = images.filter(i => i.name === results.currentProduct.chosenImage)[0]
  const additions = results.allProducts
    .slice(1)
    .map((p, index) => <LinkOrText key={index} product={p} focusedProduct={results.currentProduct} updater={() => updater(index + 1)} />)

  return (
    <PaddedCard className="card">
      <div className="card-content">
        <ProductTitle className="title">
          <LinkOrText product={results.allProducts[0]} focusedProduct={results.currentProduct} updater={() => updater(0)} />
          {results.hasAddOn && ' + '}
          {results.hasAddOn && additions}
        </ProductTitle>
        <Subtitle>{results.currentProduct.subtitle}</Subtitle>
        <ImageContainer className="card-image">{image && <NonStretchedImage fluid={image.fluid} />}</ImageContainer>
        <ColumnsWithoutMargin className="columns is-mobile">
          <div className="column">
            <div onClick={() => store.flipImage()}>
              <FontAwesomeIcon icon={faRetweet} />
            </div>
          </div>
          <UlColumnsWithoutMargin className="column colors">
            {results.currentProduct.colours.map(c => (
              <li key={c} onClick={() => store.chooseColour(results.currentProduct.product, c)}>
                <span className={`variant-${c}`}>{c}</span>
              </li>
            ))}
          </UlColumnsWithoutMargin>
        </ColumnsWithoutMargin>
        <Bullets>
          {results.currentProduct.bullets.map(b => (
            <li key={b}>{b}</li>
          ))}
        </Bullets>
      </div>
    </PaddedCard>
  )
}

export default observer(Product)
