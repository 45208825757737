import * as React from 'react'
import styled from '@emotion/styled'
import { observer } from 'mobx-react-lite'
import Observer from '@researchgate/react-intersection-observer'
import AppState, { QuestionDto } from '../appState'
import AnswerGroup from './AnswerGroup'
import Answer from './Answer'
import QuestionHeader from './QuestionHeader'

type QuestionWrapperProps = {
  isVisible: boolean
}

const QuestionWrapper = styled.div<QuestionWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 15vh;
  opacity: ${props => (props.isVisible ? 1 : 0.2)};
`

type QuestionProps = {
  question: QuestionDto
}

function QuestionInner(props: QuestionProps) {
  const store = React.useContext(AppState)
  const ref = React.useRef<HTMLDivElement>(null)

  store.updateRef(props.question.key, ref)

  return (
    <Observer rootMargin="-50% 0% -50% 0%" onChange={event => store.changeVisiblity(props.question.key, event.isIntersecting)}>
      <QuestionWrapper isVisible={props.question.visible} ref={ref}>
        <QuestionHeader header={props.question.text} />
        <AnswerGroup>
          {props.question.answers.map((a, index) => (
            <Answer key={a.key} answer={a} question={props.question} />
          ))}
        </AnswerGroup>
      </QuestionWrapper>
    </Observer>
  )
}

export const Question = observer(QuestionInner)
