import * as React from 'react'
import Slider from 'react-slick'

function ProductSection(props: { children: JSX.Element[] | JSX.Element }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "15%",
    centerMode: true
  }

  return <Slider {...settings}>
    {props.children}
    {props.children}
    {props.children}
  </Slider>
}

export default ProductSection
