export const Labels = {
  Use: {
    Question: 'use:question',
    Solo: 'use:solo',
    Couples: 'use:couples'
  },
  LookingTo: {
    Question: 'lookingto:question',
    SpiceUp: 'lookingto:spice',
    Everyday: 'lookingto:everyday'
  },
  Experience: {
    Question: 'exp:question',
    Beginner: 'exp:beginner',
    Intermediate: 'exp:intermediate',
    Advanced: 'exp:advanced'
  },
  Body: {
    Question: 'body:question',
    Vagina: 'body:vagina',
    VaginaAndClitoris: 'body:vagina-and-clitoris',
    Anus: 'body:anus',
    Clitoris: 'body:clitoris',
    Penis: 'body:penis'
  },
  Sensation: {
    Question: 'sensation:question',
    Vibration: 'sensation:vibration',
    Thrusting: 'sensation:thrusting',
    Fullness: 'sensation:fullness'
  }
}
