import * as React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import Container from './Container'
import logo from '../images/general/logo.png'
import appState from '../appState'
import { observer } from 'mobx-react-lite'

const StyledHeader = styled.header``

const HeaderInner = styled(Container)``

const HomepageLink = styled(Link)``

const TopBar = styled.div`
  display: flex;
  padding: 0.25em;
  background-image: linear-gradient(to right, #ff8f31, #ea6b80);
  color: white;
  justify-content: flex-end;
`

const TopBarItem = styled.span`
  margin-right: 2em;

  &:last-child {
    margin-right: 0;
  }
`

interface HeaderProps {
  title: string
}

const Header: React.SFC<HeaderProps> = ({ title }) => {
  const store = React.useContext(appState)

  return (
    <StyledHeader>
      <TopBar>
        <TopBarItem>
          <select value={store.language} onChange={o => store.changeLanguage(o.currentTarget.value)}>
            {Object.keys(store.languages).map(l => {
              const key = l
              const obj = store.languages[l]
              return (
                <option key={key} value={l}>
                  {obj.name}
                </option>
              )
            })}
          </select>
        </TopBarItem>
        <TopBarItem>{store.translations.topbar.warranty}</TopBarItem>
      </TopBar>
      <HomepageLink to={store.LinkToHome}>
        <img src={logo} />
      </HomepageLink>
    </StyledHeader>
  )
}

export default observer(Header)
