export enum Colours {
  vitamin = 'vitamin',
  pink = 'pink',
  blackberry = 'blackberry',
  black = 'black',
  cream = 'cream',
  freshGreen = 'fresh-green',
  candyRose = 'candy-rose',
  indiaRed = 'india-red',
  violet = 'violet',
  petrol = 'petrol',
  darkViolet = 'dark-violet',
  neonOrange = 'neon-orange',
  poolBlue = 'poolblue',
  grape = 'grape',
  caramel = 'caramel',
  nude = 'cream',
  turquoiseFluor = 'turquoise',
  pinkNeonYellow = 'pink',
  petrolNeonYellow = 'petrol',
  pinkWhite = 'pink',
  grapeWhite = 'grape',
  turquoiseWhite = 'turquoise',
  raspberry = 'raspberry',
  lightBlue = 'lightblue',
  babyRose = 'baby-rose',
  red = 'red',
  ultramarine = 'ultramarine',
  deepSeaBlueBlack = 'deep-sea-blue',
  mossGreenBlack = 'moss-green',
  blackRed = 'black-red',
  blackBlack = 'black-black',
  bordeaux = 'bordeaux',
  whiteDarkViolet = 'white-grape',
  raspberryBlack = 'raspberry-black',
  greyBlack = 'grey',
  darkBlue = 'dark-blue',
  slate = 'slate',
  blackJeans = 'black'
}

export enum Products {
  abbyG,
  amor,
  amorino,
  bBallsDuo,
  bBallUno,
  bendyBeads,
  bigBoss,
  biStronicFusion,
  bootieL,
  bootieM,
  bootieRing,
  bootieS,
  bouncer,
  bullet,
  cobraLibre2,
  darlingDevil,
  duke,
  flexiFelix,
  harness,
  jam,
  jazzie,
  ladyBi,
  laya2,
  magnum,
  manta,
  missBi,
  mrBoss,
  patchyPaul,
  share,
  sharevibe,
  stronicDrei,
  stronicG,
  stronicReal,
  stronicSurf,
  theBossStub,
  tiger,
  volita,
  volta,
  wickedWings
}

export const ProductInfo: { [product: string]: { [colour: string]: { ean: string; images: string[] } } } = {
  [Products.abbyG]: {
    [Colours.violet]: {
      ean: '4032498074061',
      images: ['ABBY G/FF_ABBY-G_07406_01.jpg', 'ABBY G/FF_ABBY-G_07406_pack.jpg']
    },
    [Colours.turquoiseFluor]: {
      ean: '4032498074733',
      images: ['ABBY G/FF_ABBY-G_07473_01.jpg', 'ABBY G/FF_ABBY-G_07473_pack.jpg']
    }
  },
  [Products.amor]: {
    [Colours.candyRose]: {
      ean: '4032498225319',
      images: ['AMOR/FF_AMOR_22531_01.jpg', 'AMOR/FF_AMOR_22531_pack.jpg']
    },
    [Colours.black]: {
      ean: '4032498225081',
      images: ['AMOR/FF_AMOR_22508_01.jpg', 'AMOR/FF_AMOR_22508_pack.jpg']
    }
  },
  [Products.amorino]: {
    [Colours.pinkNeonYellow]: {
      ean: '4032498801346',
      images: ['AMORINO/FF_AMORINO_1641836_03.jpg', 'AMORINO/FF_AMORINO_1641836_pack.jpg']
    },
    [Colours.petrolNeonYellow]: {
      ean: '4032498801353',
      images: ['AMORINO/FF_AMORINO_1641874_03.jpg', 'AMORINO/FF_AMORINO_1641874_pack.jpg']
    }
  },
  [Products.bBallUno]: {
    [Colours.greyBlack]: {
      ean: '4032498370088',
      images: ['B BALL UNO/FF_B_BALL_37008_02p.jpg', 'B BALL UNO/FF_B_BALL_37008_pack.jpg']
    },
    [Colours.raspberryBlack]: {
      ean: '4032498373089',
      images: ['B BALL UNO/FF_B_BALL_37308_02p.jpg', 'B BALL UNO/FF_B_BALL_37308_pack.jpg']
    }
  },
  [Products.bBallsDuo]: {
    [Colours.whiteDarkViolet]: {
      ean: '4032498391663',
      images: ['B BALLS DUO/FF_B_BALLS_DUO_39166_02p.jpg', 'B BALLS DUO/FF_B_BALLS_DUO_39166_pack.jpg']
    },
    [Colours.raspberryBlack]: {
      ean: '4032498393087',
      images: ['B BALLS DUO/FF_B_BALLS_DUO_39308_02p.jpg', 'B BALLS DUO/FF_B_BALLS_DUO_39308_pack.jpg']
    },
    [Colours.greyBlack]: {
      ean: '4032498390086',
      images: ['B BALLS DUO/FF_B_BALLS_DUO_39008_02p.jpg', 'B BALLS DUO/FF_B_BALLS_DUO_39008_pack.jpg']
    }
  },
  [Products.bendyBeads]: {
    [Colours.red]: {
      ean: '4032498381039',
      images: ['BENDYBEADS/FF_BENDYBEADS_38103_04.jpg', 'BENDYBEADS/FF_BENDYBEADS_38103_pack.jpg']
    },
    [Colours.black]: {
      ean: '4032498381084',
      images: ['BENDYBEADS/FF_BENDYBEADS_38108_4.jpg', 'BENDYBEADS/FF_BENDYBEADS_38108_pack.jpg']
    }
  },
  [Products.biStronicFusion]: {
    [Colours.violet]: {
      ean: '4032498802602',
      images: ['Bi STRONIC FUSION/FF_BI-STRONIC_4001106_01.jpg', 'Bi STRONIC FUSION/FF_Bi-STRONIC_4001106_pack.jpg']
    },
    [Colours.candyRose]: {
      ean: '4032498802619',
      images: ['Bi STRONIC FUSION/FF_BI-STRONIC_4001131_01.jpg', 'Bi STRONIC FUSION/FF_Bi-STRONIC_4001131_pack.jpg']
    },
    [Colours.indiaRed]: {
      ean: '4032498802626',
      images: ['Bi STRONIC FUSION/FF_BI-STRONIC_4001135_01.jpg', 'Bi STRONIC FUSION/FF_Bi-STRONIC_4001135_pack.jpg']
    }
  },
  [Products.bigBoss]: {
    [Colours.black]: {
      ean: '4032498801551',
      images: ['BIG BOSS G5/FF_BIG_BOSS_BLACK-LINE_1121808_01p.jpg', 'BIG BOSS G5/FF_BIG_BOSS_BLACK-LINE_1121808_pack.jpg']
    },
    [Colours.caramel]: {
      ean: '4032498805726',
      images: ['BIG BOSS G5/FF_BIG_BOSS_G5_1121044_01p.jpg']
    },
    [Colours.nude]: {
      ean: '4032498802572',
      images: ['BIG BOSS G5/FF_BIG_BOSS_G5_1121004SF_01.jpg', 'BIG BOSS G5/FF_BIG_BOSS_G5_1121004SF_02_pack.jpg']
    },
    [Colours.pink]: {
      ean: '4032498802596',
      images: ['BIG BOSS G5/FF_BIG_BOSS_G5_1121036SF_01.jpg', 'BIG BOSS G5/FF_BIG_BOSS_G5_1121036SF_pack.jpg']
    }
  },
  [Products.bootieS]: {
    [Colours.bordeaux]: {
      ean: '4032498249063',
      images: ['BOOTIE/FF_BOOTIE_S_24967_01p.jpg', 'BOOTIE/FF_BOOTIE_S_24967_pack.jpg']
    },
    [Colours.black]: {
      ean: '4032498249087',
      images: ['BOOTIE/FF_BOOTIE_S_24908_01p.jpg', 'BOOTIE/FF_BOOTIE_S_24908_pack.jpg']
    }
  },
  [Products.bootieM]: {
    [Colours.black]: {
      ean: '4032498248080',
      images: ['BOOTIE/FF_BOOTIE_M_24808_01p.jpg', 'BOOTIE/FF_BOOTIE_M_24808_pack.jpg']
    },
    [Colours.bordeaux]: {
      ean: '4032498248066',
      images: ['BOOTIE/FF_BOOTIE_M_24867_01p.jpg', 'BOOTIE/FF_BOOTIE_M_24867_pack.jpg']
    }
  },
  [Products.bootieL]: {
    [Colours.black]: {
      ean: '4032498247083',
      images: ['BOOTIE/FF_BOOTIE_L_24708_01p.jpg', 'BOOTIE/FF_BOOTIE_L_24708_pack.jpg']
    },
    [Colours.bordeaux]: {
      ean: '4032498247670',
      images: ['BOOTIE/FF_BOOTIE_L_24767_01p.jpg', 'BOOTIE/FF_BOOTIE_L_24767_pack.jpg']
    }
  },
  [Products.bootieRing]: {
    [Colours.slate]: {
      ean: '4032498254883',
      images: ['BOOTIE RING/FF_BOOTIE_RING_25488_01.jpg', 'BOOTIE RING/FF_BOOTIE_RING_25488_pack.jpg']
    }
  },
  [Products.bouncer]: {
    [Colours.black]: {
      ean: '4032498271088',
      images: ['BOUNCER/FF_BOUNCER_27108_03p.jpg', 'BOUNCER/FF_BOUNCER_27108_pack.jpg']
    },
    [Colours.red]: {
      ean: '4032498271033',
      images: ['BOUNCER/FF_BOUNCER_27103_03p.jpg', 'BOUNCER/FF_BOUNCER_27103_pack.jpg']
    }
  },
  [Products.bullet]: {
    [Colours.black]: {
      ean: '4032498800370',
      images: ['BULLET/FF_BULLET_1010100_02.jpg', 'BULLET/FF_BULLET_1010100_pack.jpg']
    }
  },
  [Products.cobraLibre2]: {
    [Colours.blackBlack]: {
      ean: '4032498001487',
      images: ['COBRA LIBRE II/FF_COBRA_LIBRE_II_5010808_02p.jpg', 'COBRA LIBRE II/FF_COBRA_LIBRE_II_5010808_pack.jpg']
    },
    [Colours.blackRed]: {
      ean: '4032498001470',
      images: ['COBRA LIBRE II/FF_COBRA_LIBRE_II_5010803_02p.jpg', 'COBRA LIBRE II/FF_COBRA_LIBRE_II_5010803_pack.jpg']
    }
  },
  [Products.darlingDevil]: {
    [Colours.raspberry]: {
      ean: '4032498071305',
      images: ['DARLING DEVIL/FF_DARLING_DEVIL_07130_01.jpg', 'DARLING DEVIL/FF_DARLING_DEVIL_07130_pack.jpg']
    },
    [Colours.black]: {
      ean: '4032498071084',
      images: ['DARLING DEVIL/FF_DARLING_DEVIL_07108_01.jpg', 'DARLING DEVIL/FF_DARLING_DEVIL_07108_pack.jpg']
    }
  },
  [Products.duke]: {
    [Colours.black]: {
      ean: '4032498006666',
      images: ['DUKE/FF_DUKE_5200808_03.jpg', 'DUKE/FF_DUKE_5200808_pack.jpg']
    },
    [Colours.darkBlue]: {
      ean: '4032498006673',
      images: ['DUKE/FF_DUKE_5200851_03.jpg', 'DUKE/FF_DUKE_5200851_pack.jpg']
    }
  },
  [Products.flexiFelix]: {
    [Colours.black]: {
      ean: '4032498248066',
      images: ['FLEXI FELIX/FF_FLEXI_FELIX_38008_01.jpg', 'FLEXI FELIX/FF_FLEXIFELIX_38008_pack.jpg']
    },
    [Colours.pink]: {
      ean: '4032498380360',
      images: ['FLEXI FELIX/FF_FLEXI_FELIX_38036_01.jpg', 'FLEXI FELIX/FF_FLEXIFELIX_38036_pack.jpg']
    }
  },
  [Products.harness]: {
    [Colours.blackJeans]: {
      ean: '4032498804514',
      images: ['HARNESS/FF_HARNESS_BX00004_6482.jpg', 'HARNESS/FF_HARNESS_vegan_BX00004_pack.jpg']
    }
  },
  [Products.jam]: {
    [Colours.vitamin]: {
      ean: '4032498186238',
      images: ['JAM/FF_JAM_18623_01p.jpg', 'JAM/FF_JAM_18623_pack.jpg']
    }
  },
  [Products.jazzie]: {
    [Colours.pink]: {
      ean: '4032498185361',
      images: ['JAZZIE/ff_JAZZIE_18536_01.jpg', 'JAZZIE/FF_JAZZIE_18536_pack.jpg']
    }
  },
  [Products.ladyBi]: {
    [Colours.violet]: {
      ean: '4032498802848',
      images: ['LADY Bi/FF_LADY-Bi_1211006_01p.jpg', 'LADY Bi/FF_LADY-Bi_1211006_pack.jpg']
    },
    [Colours.indiaRed]: {
      ean: '4032498802855',
      images: ['LADY Bi/FF_LADY-Bi_1211035_01p.jpg', 'LADY Bi/FF_LADY-Bi_1211035_pack.jpg']
    },
    [Colours.ultramarine]: {
      ean: '4032498802862',
      images: ['LADY Bi/FF_LADY-Bi_1211056_01p.jpg', 'LADY Bi/FF_LADY-Bi_1211056_pack.jpg']
    }
  },
  [Products.laya2]: {
    [Colours.pink]: {
      ean: '4032498805559',
      images: ['LAYA II/FF_LAYA_II_4803838_02p.jpg', 'LAYA II/FF_LAYA_II_4803838_pack.jpg']
    },
    [Colours.poolBlue]: {
      ean: '4032498805573',
      images: ['LAYA II/FF_LAYA_II_4807373_02p.jpg', 'LAYA II/FF_LAYA_II_4807373_pack.jpg']
    },
    [Colours.violet]: {
      ean: '4032498805566',
      images: ['LAYA II/FF_LAYA_II_4806666_02p.jpg', 'LAYA II/FF_LAYA_II_4806666_pack.jpg']
    }
  },
  [Products.magnum]: {
    [Colours.blackberry]: {
      ean: '4032498226644',
      images: ['MAGNUM/FF_MAGNUM_22664_02.jpg', 'MAGNUM/FF_MAGNUM_22664_pack.jpg']
    },
    [Colours.black]: {
      ean: '4032498226088',
      images: ['MAGNUM/FF_MAGNUM_22608_02_.jpg', 'MAGNUM/FF_MAGNUM_22608_pack.jpg']
    }
  },
  [Products.manta]: {
    [Colours.deepSeaBlueBlack]: {
      ean: '4032498806501',
      images: ['MANTA/FF_MANTA_5300859_01.jpg', 'MANTA/FF_MANTA_5300859_Pack.jpg']
    },
    [Colours.mossGreenBlack]: {
      ean: '4032498806518',
      images: ['MANTA/FF_MANTA_5300878_01.jpg', 'MANTA/FF_MANTA_5300878_Pack.jpg']
    }
  },
  [Products.missBi]: {
    [Colours.pinkWhite]: {
      ean: '4032498802541',
      images: ['MiSS Bi/FF_MiSS_Bi_1201036_01p.jpg', 'MiSS Bi/FF_MiSS_Bi_1201036_pack.jpg']
    },
    [Colours.black]: {
      ean: '4032498805597',
      images: ['MiSS Bi/FF_MiSS_Bi_BlackLine_1201808_01p.jpg', 'MiSS Bi/FF-MISS BI-BL-1201808-300dpi.jpg']
    },
    [Colours.grapeWhite]: {
      ean: '4032498802558',
      images: ['MiSS Bi/FF_MiSS_Bi_1201065_01p.jpg', 'MiSS Bi/FF_MiSS_Bi_1201065_pack.jpg']
    },
    [Colours.turquoiseWhite]: {
      ean: '4032498802565',
      images: ['MiSS Bi/FF_MiSS_Bi_1201073_01p.jpg', 'MiSS Bi/FF_MiSS_Bi_1201073_pack.jpg']
    }
  },
  [Products.mrBoss]: {
    [Colours.blackberry]: {
      ean: '4032498073644',
      images: ['MR BOSS/FF_MR-BOSS_07364_01.jpg', 'MR BOSS/FF_MR-BOSS_07364_pack.jpg']
    },
    [Colours.black]: {
      ean: '4032498073088',
      images: ['MR BOSS/FF_MR-BOSS_07308_01.jpg', 'MR BOSS/FF_MR-BOSS_07308_pack.jpg']
    },
    [Colours.cream]: {
      ean: '4032498073040',
      images: ['MR BOSS/FF_MR-BOSS_07304_01.jpg', 'MR BOSS/FF_MR-BOSS_07304_pack.jpg']
    }
  },
  [Products.patchyPaul]: {
    [Colours.candyRose]: {
      ean: '4032498801537',
      images: ['PATCHY PAUL G5/FF_PATCHY_PAUL_1101031_01.jpg', 'PATCHY PAUL G5/FF_PATCHY_PAUL_1101031_pack.jpg']
    },
    [Colours.freshGreen]: {
      ean: '4032498801520',
      images: ['PATCHY PAUL G5/FF_PATCHY_PAUL_1101071_01.jpg', 'PATCHY PAUL G5/FF_PATCHY_PAUL_1101071_pack.jpg']
    }
  },
  [Products.share]: {
    [Colours.violet]: {
      ean: '4032498244068',
      images: ['SHARE/FF_SHARE_24406_04.jpg', 'SHARE/FF_SHARE_24406_pack.jpg']
    },
    [Colours.black]: {
      ean: '4032498244082',
      images: ['SHARE/FF_SHARE_24408_04.jpg', 'SHARE/FF_SHARE_24408_pack.jpg']
    }
  },
  [Products.sharevibe]: {
    [Colours.darkViolet]: {
      ean: '4032498262666',
      images: ['SHAREVIBE/FF_SHAREVIBE_26266_01p.jpg', 'SHAREVIBE/FF_SHAREVIBE_26266_pack.jpg']
    },
    [Colours.nude]: {
      ean: '4032498262048',
      images: ['SHAREVIBE/FF_SHAREVIBE_26204_01p.jpg', 'SHAREVIBE/FF_SHAREVIBE_26204_pack.jpg']
    },
    [Colours.pink]: {
      ean: '4032498262369',
      images: ['SHAREVIBE/FF_SHAREVIBE_26236_01p.jpg', 'SHAREVIBE/FF_SHAREVIBE_26236_pack.jpg']
    }
  },
  [Products.stronicDrei]: {
    [Colours.blackberry]: {
      ean: '4032498801025',
      images: ['STRONIC DREI/FF_STRONICDREI_4000364_14.jpg', 'STRONIC DREI/FF_STRONIC_DREI_4000364_pack.jpg']
    }
  },
  [Products.stronicG]: {
    [Colours.pink]: {
      ean: '4032498806037',
      images: ['STRONIC G/FF_STRONIC-G_4108836_02.jpg', 'STRONIC G/FF_STRONIC-G_4108836_Pack.jpg']
    },
    [Colours.poolBlue]: {
      ean: '4032498806051',
      images: ['STRONIC G/FF_STRONIC-G_4108873_02.jpg', 'STRONIC G/FF_STRONIC-G_4108873_Pack.jpg']
    },
    [Colours.grape]: {
      ean: '4032498806044',
      images: ['STRONIC G/FF_STRONIC-G_4108865_02.jpg', 'STRONIC G/FF_STRONIC-G_4108865_Pack.jpg']
    }
  },
  [Products.stronicReal]: {
    [Colours.darkViolet]: {
      ean: '4032498806327',
      images: ['STRONIC REAL/FF_STRONIC-REAL_4128866_02.jpg', 'STRONIC REAL/FF_STRONIC-REAL_4128866_Pack.jpg']
    },
    [Colours.cream]: {
      ean: '4032498806310',
      images: ['STRONIC REAL/FF_STRONIC-REAL_4128804_02.jpg', 'STRONIC REAL/FF_STRONIC-REAL_4128804_Pack.jpg']
    },
    [Colours.candyRose]: {
      ean: '4032498806334',
      images: ['STRONIC REAL/FF_STRONIC-REAL_4128831_02.jpg', 'STRONIC REAL/FF_STRONIC-REAL_4128831_Pack.jpg']
    }
  },
  [Products.stronicSurf]: {
    [Colours.petrol]: {
      ean: '4032498806297',
      images: ['STRONIC SURF/FF_STRONIC-SURF_4118874_02.jpg', 'STRONIC SURF/FF_STRONIC-SURF_4118874_pack.jpg']
    },
    [Colours.neonOrange]: {
      ean: '4032498806280',
      images: ['STRONIC SURF/FF_STRONIC-SURF_4118823_02.jpg', 'STRONIC SURF/FF_STRONIC-SURF_4118823_pack.jpg']
    },
    [Colours.black]: {
      ean: '4032498806303',
      images: ['STRONIC SURF/FF_STRONIC-SURF_4118808_02.jpg', 'STRONIC SURF/FF_STRONIC-SURF_4118808_pack.jpg']
    }
  },
  [Products.theBossStub]: {
    [Colours.nude]: {
      ean: '4032498228044',
      images: ['THE BOSS Stubs/FF_THE_BOSS_22804_01.jpg', 'THE BOSS Stubs/FF_THE_BOSS_22804_pack.jpg']
    },
    [Colours.pink]: {
      ean: '4032498228365',
      images: ['THE BOSS Stubs/FF_THE_BOSS_22836_01.jpg', 'THE BOSS Stubs/FF_THE_BOSS_22836_pack.jpg']
    },
    [Colours.black]: {
      ean: '4032498228082',
      images: ['THE BOSS Stubs/FF_THE_BOSS_22808_01.jpg', 'THE BOSS Stubs/FF_THE_BOSS_22808_pack.jpg']
    }
  },
  [Products.tiger]: {
    [Colours.petrol]: {
      ean: '4032498802282',
      images: ['TIGER G5/FF_TIGER_G5_1131074_01p.jpg', 'TIGER G5/FF_TIGERpackage_1131074_01.jpg']
    },
    [Colours.indiaRed]: {
      ean: '4032498802275',
      images: ['TIGER G5/FF_TIGER_G5_1131035_01p.jpg', 'TIGER G5/FF_TIGERpackage_1131035_01.jpg']
    },
    [Colours.violet]: {
      ean: '4032498802268',
      images: ['TIGER G5/FF_TIGER_G5_1131006_01p.jpg', 'TIGER G5/FF_TIGERpackage_1131006_01.jpg']
    }
  },
  [Products.volita]: {
    [Colours.violet]: {
      ean: '4032498187068',
      images: ['VOLITA/FF_VOLITA_18706_02.jpg', 'VOLITA/FF_VOLITA_18706_pack.jpg']
    }
  },
  [Products.volta]: {
    [Colours.blackberry]: {
      ean: '4032498806167',
      images: ['VOLTA/FF_VOLTA_1141064_02.jpg', 'VOLTA/FF_VOLTA_1141064_Pack.jpg']
    },
    [Colours.black]: {
      ean: '4032498806532',
      images: ['VOLTA/FF_VOLTA_1141808_02.jpg', 'VOLTA/FF_VOLTA_1141808_pack.jpg']
    },
    [Colours.neonOrange]: {
      ean: '4032498806150',
      images: ['VOLTA/FF_VOLTA_1141023_02.jpg', 'VOLTA/FF_VOLTA_1141023_Pack.jpg']
    },
    [Colours.petrol]: {
      ean: '4032498806174',
      images: ['VOLTA/FF_VOLTA_1141074_02.jpg', 'VOLTA/FF_VOLTA_1141074_Pack.jpg']
    }
  },
  [Products.wickedWings]: {
    [Colours.lightBlue]: {
      ean: '4032498070575',
      images: ['WICKED WINGS/FF_WICKED_WINGS_07057_01.jpg', 'WICKED WINGS/FF_WICKED_WINGS_07057_pack.jpg']
    },
    [Colours.babyRose]: {
      ean: '4032498070384',
      images: ['WICKED WINGS/FF_WICKED_WINGS_07038_01.jpg', 'WICKED WINGS/FF_WICKED_WINGS_07038_pack.jpg']
    }
  }
}
