type LanguageDefinition = {
  name: string
}

export const Languages: { [index: string]: LanguageDefinition } = {
  [`en-GB`]: {
    name: `English`
  },
  [`ja`]: {
    name: `日本語`
  },
  [`zh`]: {
    name: `中文`
  },
  [`ko`]: {
    name: `한국어`
  }
}

const defaultLanguage = 'en-GB'

const getBrowserLanguages = (): string[] => {
  const nav = window.navigator
  const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage']
  let i
  let language

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages)) {
    return nav.languages
  }

  for (i = 0; i < browserLanguagePropertyKeys.length; i + 1) {
    language = (nav as any)[browserLanguagePropertyKeys[i]]
    if (language && language.length) {
      return [language]
    }
  }

  return [defaultLanguage]
}

export const DefaultLanguage = () => {
  if (typeof window !== `undefined`) {
    const possibleLanguages = Object.keys(Languages)
    const matchingLanguages = getBrowserLanguages().filter(l => possibleLanguages.includes(l))

    return matchingLanguages[0] || defaultLanguage
  }

  return defaultLanguage
}
