import * as React from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import styled from '@emotion/styled'

import AppState, { QuestionDto, AnswerDto } from '../appState'

const StyledButton = styled.div`
  white-space: normal;
  height: unset;
  min-height: 2.25em;
`

type AnswerProps = {
  question: QuestionDto
  answer: AnswerDto
}

function Answer(props: AnswerProps) {
  const store = React.useContext(AppState)
  const names = classNames({
    'is-primary': props.answer.isChosen
  })

  return (
    <div className="column is-full-mobile is-half-tablet is-one-third-desktop">
      <StyledButton
        className={`button is-large is-fullwidth ${names}`}
        onClick={() => store.answerQuestion(props.question.key, props.answer.key)}
      >
        {props.answer.text}
      </StyledButton>
    </div>
  )
}

export default observer(Answer)
