import * as React from 'react'
import { observable, computed, action } from 'mobx'
import scrollToComponent from 'react-scroll-to-component-ssr'
import TagManager from 'react-gtm-module'
import { createBrowserHistory, History } from 'history'

import { Questionnaire } from './Questionnaire'
import { Labels } from './Labels'
import { Question } from './Question'
import { Translations } from './Translations'
import { Products, Colours, ProductInfo } from './Products'
import { Results } from './Results'
import { ResultsKey } from '../pages/quiz'
import { DefaultLanguage, Languages } from './Languages'

function alwaysShow() {
  return true
}

export type QuestionDto = {
  key: string
  text: string
  answers: AnswerDto[]
  visible: boolean
}

export type AnswerDto = {
  key: string
  text: string
  isChosen: boolean
}

export type ProductDto = {
  product: Products
  name: string
  subtitle: string
  bullets: string[]
  colours: Colours[]
  chosenImage: string
  chosenColour: string
}

export type VisibleResult = {
  hasAddOn: boolean
  currentProduct: ProductDto
  allProducts: ProductDto[]
}

export class AppState {
  history: History | undefined = undefined

  @observable
  language = ''

  @observable
  translations: Translations = {
    questions: {},
    products: {},
    topbar: {
      warranty: ''
    },
    landing: {
      title: '',
      blurb: '',
      cta: '',
      silicone: '',
      waterproof: '',
      rechargable: '',
      germanEngineered: '',
      ['about:title']: '',
      ['about:body']: ''
    },
    results: {
      title: '',
      info: ''
    },
    footer: {
      contact: '',
      copyright: '',
      faq: '',
      legal: '',
      press: '',
      storefinder: ''
    }
  }

  @observable
  isFlipped: boolean = false

  @observable
  seenQuestions: { [key: string]: boolean } = {
    [Labels.Use.Question]: true
  }

  @observable
  answers: { [question: string]: string } = {}

  @observable
  visibility: { [question: string]: boolean } = {}

  @observable
  chosenColours: { [product: string]: string } = {}

  @observable
  subProductFocus: { [product: string]: number } = {}

  languages = Languages

  refs: { [question: string]: React.RefObject<HTMLDivElement> } = {}

  @computed
  get isDefaultLanguage() {
    return this.language === defaultLanguage
  }

  @computed
  get LinkToQuiz() {
    return `/quiz/${this.isDefaultLanguage || typeof this.history === 'undefined' ? '' : this.history.location.search}`
  }

  @computed
  get LinkToHome() {
    return `/${this.isDefaultLanguage || typeof this.history === 'undefined' ? '' : this.history.location.search}`
  }

  @computed
  get isDone() {
    return this._products.length > 0
  }

  @computed
  get answerKey() {
    return Object.keys(this.answers).join(',')
  }

  @computed
  get visibleQuestions(): QuestionDto[] {
    if (this.translations === undefined) {
      return []
    }

    const t = this.translations.questions
    const mapped = Questionnaire.filter(q => this.seenQuestions[q.label] === true).map(q => {
      return {
        key: q.label,
        text: t[q.label],
        answers: q.answers
          .filter(a => (a.shouldShow || alwaysShow)(Object.values(this.answers)))
          .map(a => {
            return {
              key: a.label,
              text: t[a.label],
              isChosen: this.answers[q.label] === a.label
            }
          }),
        visible: this.visibility[q.label]
      }
    })

    return mapped
  }

  @computed
  get products(): VisibleResult[] {
    return this._products.map((list, resultIndex) => {
      const focusedProduct = this.subProductFocus[`${this.answerKey},${resultIndex}`] || 0

      const allProducts = list.map(p => {
        const productKey = Products[p]
        const info = ProductInfo[p]

        const chosenImageIndex = this.isFlipped ? 1 : 0
        const colours = Object.keys(info).map(c => c as Colours)
        const chosenColour = this.chosenColours[p] || colours[0]
        const chosenImage = info[chosenColour].images[chosenImageIndex]
        const translations = this.translations.products[productKey] || {}

        return {
          colours,
          chosenImage,
          chosenColour,
          product: p,
          name: translations.name || Products[p],
          subtitle: translations.subtitle || '',
          bullets: translations.bullets || ['']
        }
      })

      const results = {
        allProducts,
        hasAddOn: list.length > 1,
        currentProduct: allProducts[focusedProduct]
      }

      return results
    })
  }

  @computed
  get _products() {
    const use = this.answers[Labels.Use.Question]
    const exp = this.answers[Labels.Experience.Question]
    const body = this.answers[Labels.Body.Question]
    const sensation = this.answers[Labels.Sensation.Question]
    const hasMinAnswers = use && exp && body

    if (!hasMinAnswers) {
      return []
    }

    try {
      const initialResults = Results[use][exp][body]
      if (Array.isArray(initialResults)) {
        return initialResults
      }

      if (typeof initialResults === 'object') {
        if (sensation) {
          return (initialResults as { [index: string]: Products[][] })[sensation] || []
        }

        return []
      }

      return []
    } catch {
      return []
    }
  }

  @action
  answerQuestion(questionKey: string, answerKey: string) {
    const question: Question = Questionnaire.filter(q => q.label === questionKey)[0]
    if (question === undefined) {
      console.error('question unknown: ', questionKey)
      return
    }

    const answer = question.answers.filter(a => a.label === answerKey)[0]
    if (answer === undefined) {
      console.error('answer unknown: ', answerKey)
      return
    }

    const isPotentiallyDone = answer.done || question.done || false
    const isDone = typeof isPotentiallyDone === 'function' ? isPotentiallyDone(Object.values(this.answers)) : isPotentiallyDone
    this.answers[questionKey] = answerKey
    if (typeof question.cleanup === 'function') {
      question.cleanup(this.answers, this.seenQuestions)
    }

    setTimeout(() => {
      TagManager.dataLayer({
        question: questionKey,
        answer: answerKey
      })
    })

    if (isDone) {
      this._clearAnswersAfter(question)

      setTimeout(() => {
        const ref = this.refs[ResultsKey]
        if (!!ref && !!ref.current) {
          scrollToComponent(ref.current, {
            ease: 'outQuad',
            duration: 500,
            align: 'top'
          })
        }
      })
      setTimeout(() => {
        TagManager.dataLayer({
          event: 'finished-quiz'
        })
      })

      return
    }

    const leadsTo = this._getLeadsTo(question)
    if (typeof leadsTo === 'string') {
      this.seenQuestions[leadsTo] = true

      setTimeout(() => {
        const ref = this.refs[leadsTo!]
        if (!!ref && !!ref.current) {
          scrollToComponent(ref.current, {
            ease: 'outQuad',
            duration: 500
          })
        }
      })

      return
    }
  }

  @action
  changeVisiblity(questionKey: string, isIntersecting: boolean): void {
    this.visibility[questionKey] = isIntersecting
  }

  updateRef(key: string, ref: React.RefObject<HTMLDivElement>) {
    this.refs[key] = ref
  }

  @action
  _clearAnswersAfter(question: Question) {
    const leadsTo = this._getLeadsTo(question)
    const next = Questionnaire.filter(q => q.label === leadsTo)[0]
    if (next !== undefined) {
      delete this.answers[next.label]
      delete this.seenQuestions[next.label]
      this._clearAnswersAfter(next)
    }
  }

  @action
  changeLanguage(newLanguage: string, noChange: boolean = false) {
    this.translations = require(`./${newLanguage}.json`) as Translations
    this.language = newLanguage

    if (noChange) {
      return
    }

    if (this.history) {
      this.history.push({
        pathname: location.pathname,
        search: this.isDefaultLanguage ? '' : `?lang=${newLanguage}`
      })
    }
  }

  @action
  chooseColour(product: Products, colour: Colours) {
    this.chosenColours[product] = colour
  }

  @action
  flipImage() {
    this.isFlipped = !this.isFlipped
  }

  @action
  setSubProductFocus(resultIndex: number, productIndex: number) {
    const key = `${this.answerKey},${resultIndex}`
    this.subProductFocus[key] = productIndex
  }

  _getLeadsTo(question: Question) {
    if (typeof question.leadsTo === 'string') {
      return question.leadsTo
    }

    if (typeof question.leadsTo === 'function') {
      return question.leadsTo(Object.values(this.answers))
    }

    return undefined
  }
}

const appState = new AppState()
const defaultLanguage = DefaultLanguage()
const tagManagerArgs = {
  gtmId: 'GTM-5R86MCQ'
}

if (typeof window !== `undefined`) {
  TagManager.initialize(tagManagerArgs)

  const history = createBrowserHistory()
  appState.history = history
  function init() {
    const params = new URLSearchParams(history.location.search)
    const lang = params.get('lang') || defaultLanguage
    appState.changeLanguage(lang, true)
  }
  init()

  history.listen((location, act) => {
    if (act === 'POP') {
      const params = new URLSearchParams(location.search)
      const lang = params.get('lang') || defaultLanguage

      appState.changeLanguage(lang, true)
    }
  })
}

export default React.createContext(appState)
