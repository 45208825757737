import * as React from 'react'
import styled from '@emotion/styled'
import { observer } from 'mobx-react-lite'
import AppState from '../appState'
import FbIcon from '../images/general/FB_Icon.svg'
import InstaIcon from '../images/general/INSTA_Icon.svg'
import TwitterIcon from '../images/general/TWITTER_Icon.svg'
import YoutubeIcon from '../images/general/YOUTUBE_Icon.svg'

const StyledFooter = styled.footer`
  padding: 0;
`

const FooterLink = styled.a`
  text-transform: uppercase;
  color: white;
  font-family: KievitPro-Bold;

  &:visited {
    color: white;
  }

  &:hover {
    color: white;
  }
`

const Copyright = styled.div`
  padding-left: 1em !important;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #aa9fa1;
`

const IconArea = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Icon = styled.img`
  padding: 15%;
  height: 50px;
  width: 50px;
`

const Footer = () => {
  const store = React.useContext(AppState)
  const tx = store.translations.footer

  return (
    <StyledFooter className="footer is-paddingless">
      <div className="columns is-mobile is-marginless">
        <Copyright className="column is-6 is-paddingless">{tx.copyright}</Copyright>
        <IconArea className="column is-6 is-paddingless">
          <a href="https://www.instagram.com/funfactory/?hl=en" target="_blank">
            <Icon src={InstaIcon} />
          </a>
          <a href="https://twitter.com/funfactory_intl" target="_blank">
            <Icon src={TwitterIcon} />
          </a>
          <a href="https://www.facebook.com/funfactory/" target="_blank">
            <Icon src={FbIcon} />
          </a>
          <a href="https://www.youtube.com/user/funfactorygmbh" target="_blank">
            <Icon src={YoutubeIcon} />
          </a>
        </IconArea>
      </div>
    </StyledFooter>
  )
}

export default observer(Footer)
