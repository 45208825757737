import { Labels } from './Labels'
import { Products } from './Products'

export const Results = {
  [Labels.Use.Solo]: {
    [Labels.Experience.Beginner]: {
      [Labels.Body.Vagina]: {
        [Labels.Sensation.Vibration]: [[Products.jam], [Products.jazzie], [Products.mrBoss]],
        [Labels.Sensation.Thrusting]: [[Products.stronicG], [Products.stronicReal]],
        [Labels.Sensation.Fullness]: [[Products.amor], [Products.magnum]]
      },
      [Labels.Body.VaginaAndClitoris]: [[Products.amorino], [Products.missBi], [Products.wickedWings]],
      [Labels.Body.Anus]: {
        [Labels.Sensation.Vibration]: [[Products.flexiFelix, Products.bullet], [Products.darlingDevil], [Products.amorino]],
        [Labels.Sensation.Fullness]: [[Products.bootieS], [Products.bBallUno]]
      },
      [Labels.Body.Clitoris]: [[Products.volita], [Products.jam], [Products.bullet], [Products.laya2]],
      [Labels.Body.Penis]: [[Products.manta], [Products.cobraLibre2]]
    },
    [Labels.Experience.Intermediate]: {
      [Labels.Body.Vagina]: {
        [Labels.Sensation.Vibration]: [[Products.patchyPaul], [Products.mrBoss], [Products.tiger]],
        [Labels.Sensation.Thrusting]: [[Products.stronicReal], [Products.stronicSurf], [Products.stronicG]],
        [Labels.Sensation.Fullness]: [[Products.amor], [Products.magnum], [Products.bouncer]]
      },
      [Labels.Body.VaginaAndClitoris]: {
        [Labels.Sensation.Vibration]: [[Products.missBi], [Products.ladyBi], [Products.amorino], [Products.tiger], [Products.darlingDevil]],
        [Labels.Sensation.Thrusting]: [[Products.biStronicFusion], [Products.stronicDrei]]
      },
      [Labels.Body.Anus]: {
        [Labels.Sensation.Vibration]: [[Products.bendyBeads, Products.bullet], [Products.duke], [Products.tiger], [Products.missBi]],
        [Labels.Sensation.Fullness]: [[Products.bootieM], [Products.bBallsDuo], [Products.magnum], [Products.amor]],
        [Labels.Sensation.Thrusting]: [[Products.stronicDrei], [Products.biStronicFusion]]
      },
      [Labels.Body.Clitoris]: [[Products.laya2], [Products.volta]],
      [Labels.Body.Penis]: [[Products.manta], [Products.cobraLibre2]]
    },
    [Labels.Experience.Advanced]: {
      [Labels.Body.Vagina]: {
        [Labels.Sensation.Vibration]: [[Products.bigBoss], [Products.patchyPaul], [Products.abbyG]],
        [Labels.Sensation.Thrusting]: [[Products.stronicReal], [Products.stronicSurf], [Products.stronicG]],
        [Labels.Sensation.Fullness]: [[Products.theBossStub], [Products.bouncer]]
      },
      [Labels.Body.VaginaAndClitoris]: {
        [Labels.Sensation.Vibration]: [[Products.ladyBi], [Products.tiger], [Products.darlingDevil]],
        [Labels.Sensation.Thrusting]: [[Products.biStronicFusion], [Products.stronicDrei]]
      },
      [Labels.Body.Anus]: {
        [Labels.Sensation.Vibration]: [[Products.bendyBeads, Products.bullet], [Products.duke], [Products.tiger], [Products.ladyBi]],
        [Labels.Sensation.Fullness]: [[Products.bootieL], [Products.bBallsDuo], [Products.theBossStub], [Products.bouncer]],
        [Labels.Sensation.Thrusting]: [[Products.stronicDrei], [Products.biStronicFusion]]
      },
      [Labels.Body.Clitoris]: [[Products.laya2], [Products.volta]],
      [Labels.Body.Penis]: [[Products.manta], [Products.cobraLibre2]]
    }
  },
  [Labels.Use.Couples]: {
    [Labels.Experience.Beginner]: {
      [Labels.Body.Vagina]: {
        [Labels.Sensation.Vibration]: [[Products.laya2], [Products.amorino], [Products.missBi]],
        [Labels.Sensation.Thrusting]: [[Products.stronicReal], [Products.stronicG]],
        [Labels.Sensation.Fullness]: [[Products.amor, Products.harness], [Products.magnum, Products.harness]]
      },
      [Labels.Body.VaginaAndClitoris]: [[Products.laya2], [Products.amorino], [Products.missBi]],
      [Labels.Body.Anus]: {
        [Labels.Sensation.Vibration]: [[Products.flexiFelix, Products.bullet], [Products.duke], [Products.amorino]],
        [Labels.Sensation.Fullness]: [[Products.flexiFelix], [Products.bootieS], [Products.bBallUno]]
      },
      [Labels.Body.Clitoris]: [[Products.laya2], [Products.volita], [Products.jam]],
      [Labels.Body.Penis]: [[Products.manta], [Products.cobraLibre2]]
    },
    [Labels.Experience.Intermediate]: {
      [Labels.Body.Vagina]: {
        [Labels.Sensation.Vibration]: [[Products.volta], [Products.missBi], [Products.tiger], [Products.laya2]],
        [Labels.Sensation.Thrusting]: [[Products.stronicReal], [Products.stronicSurf], [Products.stronicG]],
        [Labels.Sensation.Fullness]: [[Products.amor, Products.harness], [Products.magnum, Products.harness], [Products.share]]
      },
      [Labels.Body.VaginaAndClitoris]: {
        [Labels.Sensation.Vibration]: [[Products.darlingDevil], [Products.missBi], [Products.tiger], [Products.laya2]],
        [Labels.Sensation.Thrusting]: [[Products.biStronicFusion], [Products.stronicDrei]]
      },
      [Labels.Body.Anus]: {
        [Labels.Sensation.Vibration]: [[Products.bendyBeads, Products.bullet], [Products.duke], [Products.tiger]],
        [Labels.Sensation.Fullness]: [[Products.bootieM], [Products.bBallsDuo], [Products.bootieRing], [Products.magnum, Products.harness]],
        [Labels.Sensation.Thrusting]: [[Products.stronicDrei], [Products.biStronicFusion]]
      },
      [Labels.Body.Clitoris]: [[Products.volta], [Products.laya2]],
      [Labels.Body.Penis]: [[Products.manta], [Products.cobraLibre2]]
    },
    [Labels.Experience.Advanced]: {
      [Labels.Body.Vagina]: {
        [Labels.Sensation.Vibration]: [[Products.patchyPaul], [Products.sharevibe], [Products.laya2], [Products.bigBoss]],
        [Labels.Sensation.Thrusting]: [[Products.stronicReal], [Products.stronicSurf], [Products.stronicG]],
        [Labels.Sensation.Fullness]: [[Products.bouncer, Products.harness], [Products.theBossStub, Products.harness], [Products.share]]
      },
      [Labels.Body.VaginaAndClitoris]: {
        [Labels.Sensation.Vibration]: [[Products.ladyBi], [Products.missBi], [Products.tiger]],
        [Labels.Sensation.Thrusting]: [[Products.biStronicFusion], [Products.stronicDrei]]
      },
      [Labels.Body.Anus]: {
        [Labels.Sensation.Vibration]: [[Products.duke], [Products.sharevibe], [Products.ladyBi]],
        [Labels.Sensation.Fullness]: [
          [Products.bootieL],
          [Products.bBallsDuo],
          [Products.theBossStub, Products.harness],
          [Products.bouncer, Products.harness]
        ],
        [Labels.Sensation.Thrusting]: [[Products.stronicDrei], [Products.biStronicFusion]]
      },
      [Labels.Body.Clitoris]: [[Products.laya2], [Products.volta]],
      [Labels.Body.Penis]: [[Products.manta], [Products.cobraLibre2]]
    }
  }
}
