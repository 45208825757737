import { Question } from './Question'
import { Labels } from './Labels'

const userIsNotBeginner = (answers: string[]) =>
  answers.includes(Labels.Experience.Intermediate) || answers.includes(Labels.Experience.Advanced)

export const Questionnaire: Question[] = [
  {
    label: Labels.Use.Question,
    answers: [
      {
        label: Labels.Use.Solo
      },
      {
        label: Labels.Use.Couples
      }
    ],
    leadsTo: (answers: string[]) => (answers.includes(Labels.Use.Couples) ? Labels.LookingTo.Question : Labels.Experience.Question),
    cleanup: (answers, seenQuestions) => {
      const currentAnswer = answers[Labels.Use.Question]
      if (currentAnswer === Labels.Use.Solo) {
        delete answers[Labels.LookingTo.Question]
        delete seenQuestions[Labels.LookingTo.Question]
      }
    }
  },
  {
    label: Labels.LookingTo.Question,
    answers: [
      {
        label: Labels.LookingTo.SpiceUp
      },
      {
        label: Labels.LookingTo.Everyday
      }
    ],
    leadsTo: Labels.Experience.Question
  },
  {
    label: Labels.Experience.Question,
    answers: [
      {
        label: Labels.Experience.Beginner
      },
      {
        label: Labels.Experience.Intermediate
      },
      {
        label: Labels.Experience.Advanced
      }
    ],
    leadsTo: Labels.Body.Question
  },
  {
    label: Labels.Body.Question,
    answers: [
      {
        label: Labels.Body.Vagina
      },
      {
        label: Labels.Body.VaginaAndClitoris,
        done: (answers: string[]) => answers.includes(Labels.Experience.Beginner)
      },
      {
        label: Labels.Body.Clitoris,
        done: true
      },
      {
        label: Labels.Body.Anus
      },
      {
        label: Labels.Body.Penis,
        done: true
      }
    ],
    leadsTo: Labels.Sensation.Question
  },
  {
    label: Labels.Sensation.Question,
    answers: [
      {
        label: Labels.Sensation.Vibration,
        shouldShow: (answers: string[]) =>
          answers.includes(Labels.Body.Anus) ||
          answers.includes(Labels.Body.Vagina) ||
          (answers.includes(Labels.Body.VaginaAndClitoris) && userIsNotBeginner(answers))
      },
      {
        label: Labels.Sensation.Thrusting,
        shouldShow: (answers: string[]) =>
          answers.includes(Labels.Body.Vagina) || ((answers.includes(Labels.Body.VaginaAndClitoris) || answers.includes(Labels.Body.Anus)) && userIsNotBeginner(answers))
      },
      {
        label: Labels.Sensation.Fullness,
        shouldShow: (answers: string[]) => answers.includes(Labels.Body.Anus) || answers.includes(Labels.Body.Vagina)
      }
    ],
    done: true
  }
]
